
export default {
  name: "QuantityOperator",
  props: {
    productQuantity: {
      type: Number,
      required: true,
      default: 1,
    },
    item: {
      type: Object,
      default: () => {},
      required: false,
    },
    modifiers: {
      type: Array,
      default: () => [],
      required: false,
    },
    minQuantity: {
      type: Number,
      default: 1,
      required: false,
    },
    maxQuantity: {
      type: Number,
      default: 100,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    showBin: {
      type: Boolean,
      default: true,
      required: false,
    },
    disableMinus: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    quantity: 0,
    showPicker: false,
  }),
  computed: {
    isMax() {
      return this.maxQuantity === 0
    },
  },
  watch: {
    productQuantity(to) {
      this.quantity = to
    },
  },
  mounted() {
    this.quantity = this.productQuantity
  },
  methods: {
    changeQuantity(plus) {
      let quantity = this.quantity
      !plus && (quantity -= 1)
      plus &&
        (quantity >= this.minQuantity
          ? (quantity += 1)
          : (quantity = this.minQuantity))
      this.$emit("returnQuantity", {
        product: { ...this.item, quantity },
        appliedModifiers: this.modifiers,
      })
    },
    updateQuantity() {
      this.showPicker = false
      this.$emit("returnQuantity", {
        product: { ...this.item, quantity: this.quantity },
        appliedModifiers: this.modifiers,
      })
    },
  },
}
